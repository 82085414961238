<template>
  <div class="index" v-loading="pageLoading" ref="index">
    <div class="index-main">
      <div class="classification">
        <ul class="list">
          
          <li
            class="list-item"
            v-for="(item, index) of myCateList"
            :key="item.category_id"
            @click="item.category_id?  toList('LessonTuoyu', item.category_id) : jumpLesson(item)"
          >
            <span>
              {{ item.category_name }}
            </span>
            <i class="el-icon-arrow-right"></i>
          </li>
          <li class="list-item" @click="jumpList('liveTuoyu')">
            <span> 直播回放 </span>
            <i class="el-icon-arrow-right"></i>
          </li>
          <!-- <li class="list-item" @click="tojiazhang()">
            <span> 家长学堂 </span>
            <i class="el-icon-arrow-right"></i>
          </li> -->
        </ul>
      </div>
      <el-carousel :interval="3000" arrow="hover" height="372px" class="banner">
        <el-carousel-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            height="372"
            :src="item.cover"
            fit="cover"
            @click="jumpBannerLesson(item)"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- <van-swipe class="banner" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) of banner" :key="index">
          <van-image
            class="banner-item"
            width="100%"
            height="372"
            :src="item.cover"
            fit="cover"
            @click="jumpBannerLesson(item)"
          />
        </van-swipe-item>
      </van-swipe> -->
      <div class="userInfo" v-if="isLogin">
        <div class="userLogo">
          <img
            :src="
              (user && user.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'
            "
          />
          <span>{{ user ? user.nickname : "用户昵称" }}</span>
        </div>
        <div class="studyTime">
          <p>
            今日学习<b>{{ studyInfo.today_time }}</b
            >分钟
          </p>
          <p>
            连续学习<b>{{ studyInfo.day_number }}</b
            >天
          </p>
        </div>
        <p class="btn" @click="jumpList('purchased2')">进入已购</p>
      </div>
      <div class="noLogin" v-else>
        <div class="btn">
          <p @click="$store.commit('changeLoginShow', true)">登录</p>
          <span></span>
          <p @click="$store.commit('changeLoginShow', true)">注册</p>
        </div>
      </div>
    </div>
    <listTrain
      style="margin-top: 80px;"
      v-if="list.length"
      :list="list"
      :padBottom="true"
      :onlyExamin="true"
      name="全国托育服务技能大赛培训班"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg"
    >
    </listTrain>
    
    <listCat
      v-if="listFL.length"
      :list="listFL"
      btnText="立即学习"
      name="政策法规系列课程"
      :id="208"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E9%A6%96%E9%A1%B5%E5%9B%BE%E6%A0%87_03.png"
    ></listCat>
   
    <!-- 托育 -->
   
    <!-- {{ jnLesson.list[0] }} -->
    
   
    <!-- <list
      v-if="zqLesson.length"
      :list="zqLesson"
      name="早期能力发展"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_gp.jpg"
      id="164"
    ></list>
    <list
      v-if="tyLesson.length"
      :list="zqLesson"
      name="托育管理指导"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E5%9B%BE%E6%A0%87_06.png"
      id="164"
    ></list> -->
    <listTrain
      style="margin-top: 80px;"
      v-if="gpLesson.length"
      :list="gpLesson"
      name="托育机构保育人员"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg"
    >
    </listTrain>
    <listTrain
      :padBottom="true"
      v-if="gpLesson.length"
      :list="gpLesson.slice(1)"
      name="托育机构负责人"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E9%A6%96%E9%A1%B5%E5%9B%BE%E6%A0%87_06.png"
    >
    </listTrain>
   
    
    <listCat
      v-if="jnLesson.length"
      :list="jnLesson"
      :priceHide="true"
      btnText="立即学习"
      name="职业认证培训"
      :id="122"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_jn.jpg"
    ></listCat>
     <listCat
      v-if="lessonList.length"
      :list="lessonList"
      name="岗位职责培训"
      btnText="立即学习"
      :id="207"
      :imgUrl="img1"
    ></listCat>
    <listCat
      v-if="sxList.length"
      :list="sxList"
      btnText="立即学习"
      name="实训室操作培训"
      :id="226"
      :imgUrl="img1"
    ></listCat>
    <div class="live" v-if="liveData.length">
      <div class="module-title">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_live.jpg"
        />
        <p>直播回放</p>
        <span @click="jumpList('liveTuoyu')"
          >更多
          <van-icon name="arrow" color="#006bb3" size="16" />
        </span>
      </div>
      <div class="course-list">
        <div
          class="course-card levelItem"
          style="width: calc((100% - 60px) * 0.25)"
          v-for="item of liveData[0].data.slice(0, 4)"
          :key="item.id"
          @click="liveplay(item)"
        >
          <div class="course-card-cover">
            <van-image
              class="course-cover"
              width="100%"
              :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
              :src="item.img_url"
            >
              <template v-slot:error>
                <van-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                ></van-image>
              </template>
            </van-image>
            <span class="course-card-cover-tag">直播</span>
          </div>
          <div class="course-card-detail">
            <div class="course-card-title">
              <p>{{ item.name }}</p>
            </div>
            <div class="card-btn">观看回放</div>
          </div>
        </div>
      </div>
    </div>
     <!-- <listCat
      v-if="vipLesson.length"
      :list="vipLesson"
      name="岗位技能培训"
      :id="163"
      imgUrl="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_vip.jpg"
    ></listCat> -->
    <!-- <div class="course-card2" v-if="jnLesson.length">
      <div class="module-title">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/titleImg_pp.jpg"
        />
        <p>陪跑计划</p>
        <span @click="toList('LessonTuoyu', 161)"
          >更多
          <van-icon name="arrow" color="#006bb3" size="16" />
        </span>
      </div>
      <div
        class="course-card2-item"
        v-for="card of jnLesson.list[0]"
        :key="card.id"
        @click="jumpLesson(card)"
      >
        <div
          class="course-card-cover"
          :style="{ height: ((width - 60) * 0.25 * 9) / 16 + 'px' }"
        >
          <div class="tip">
           //  is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免
            <img :src="$free" v-if="card.is_free == 2" />
            <img :src="$vip" v-else-if="card.is_vip_flat == 1" />
            <img :src="$noVip" v-else />
          </div>
          <van-image
            class="course-cover"
            width="100%"
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="card.cover_mobile"
          >
            <template v-slot:error>
              <van-image
                style="width: 100%; height: 100%"
                fit="cover"
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
              ></van-image>
            </template>
          </van-image>
        </div>
        <div class="course-card-detail">
          <p class="course-card-title">{{ card.name }}</p>
          <p class="detail">{{ card.description }}</p>
          <p class="lessonTime">共计{{ card.study_times }}学时（线上 学时，线下 学时）</p>
          <div>
            <p class="price">
              ￥<b>{{ card.price }}</b>
            </p>
            <p>{{ card.ordernum }}人已学习</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="idea">
      <div class="module-title">
        继续教育
        <span @click="jumpList(1)"
          >更多
          <van-icon name="arrow" color="#999" size="16" />
        </span>
      </div>
      <div class="idea-nav">
        <div
          class="idea-nav-item"
          v-for="(item, index) of category"
          :key="item.id"
          @click="ideaNavChange(index)"
          :class="{ active: ideaActive == index }"
        >
          {{ item.category_name }}
        </div>
      </div>
      <div class="idea-list">
        <div
          class="idea-card card-hover"
          v-for="item of ideaData"
          :key="item.id"
          @click="jumpIdea(item)"
        >
          <div class="card-title">
            {{ item.name }}
          </div>
          <div class="card-subtitle">
            {{ item.content[0].value }}
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="idea">
      <div class="module-title">
        锦囊
        <span @click="jumpList('idea')"
          >更多
          <van-icon name="arrow" color="#999" size="16" />
        </span>
      </div>
      <div class="idea-nav">
        <div
          class="idea-nav-item"
          v-for="(item, index) of ideaNavData"
          :key="item.id"
          @click="ideaNavChange(index)"
          :class="{ active: ideaActive == index }"
        >
          {{ item }}
        </div>
      </div>
      <div class="idea-list">
        <div
          class="idea-card card-hover"
          v-for="item of ideaData"
          :key="item.id"
          @click="jumpIdea(item)"
        >
          <div class="card-title">
            {{ item.name }}
          </div>
          <div class="card-subtitle">
            {{ item.content[0].value }}
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
document.ondragstart = function () {
  return false;
};
import list from "@/components/IndexList";
import listCat from "@/components/IndexListCat";
import listTrain from "@/components/IndexListTrain";
import img1 from "@/assets/images/homeImg1.png"
export default {
  name: "index",
  components: { list, listCat, listTrain },
  data() {
    return {
      myCateList:[{
        category_name:'政策法规系列课程',
        category_id: 208
      },{
        category_name:'托育机构保育人员',
        id: 588
      },{
        category_name:'托育机构负责人',
        id: 587
      },{
        category_name:'职业认证培训',
        category_id: 122
      },{
        category_name:'岗位职责培训',
        category_id: 207
      },{
        category_name:'实训室操作培训',
        category_id: 226
      }],
      cateList: [],
      active: 0,
      pageLoading: true,
      imgScaleIndex: '',
      img1,
      vipLesson: [],
      lessonList:[],
      sxList:[],
      vipIndex: 0,
      gpLesson: [],
      jnLesson: [],
      ppLesson: [],
      zqLesson: [],
      listFL:[],//法律

      hotData: [],
      topData: [],
      goodData: [],
      certificate: [],
      ideaActive: 0,
      ideaNavData: [],
      ideaData: [],
      liveData: [],
      certData: [],
      loading: true,
      checked: false,
      ideaAllData: [],
      category: [],
      banner: [],
      demonstrate: [],
      width: 1200,

      list:[],

      tyLesson: [],

      // 婴幼
      yyList2: [],
      yyList2Name: '',
      yyList3: [],
      yyList3Name: '',
      liveImg1: [
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E6%95%99%E7%A0%94.jpg",
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%9B%B4%E6%92%AD%E8%AF%BE-%E8%BF%90%E8%90%A5%E7%AE%A1%E7%90%86.jpg",
      ],
      liveImg2: [
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%AE%B6%E9%95%BF%E5%A4%A7%E8%AE%B2%E5%A0%82.jpg",
      ],
    };
  },
  async mounted() {
    this.$store.dispatch("getStudy");
    this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
    await this.getData();
    // this.$nextTick(()=>{
    //   this.width = this.$refs.index.offsetWidth

    // })
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    indexData() {
      console.log("this.$store.state.plat", this.$store.state.plat.logo);
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.$store.state.plat.indexData;
      } else {
        let arr = this.category.map((e) => {
          return e;
        });
        arr.push({
          category_id: 0,
          category_name: "全部分类",
        });
        return arr;
      }
    },
    liveImg() {
      if (this.$store.state.plat_id == "119" || this.$store.state.plat_id == "118") {
        return this.liveImg1;
      } else {
        return this.liveImg2;
      }
    },
  },
  watch:{
    plat(){
      // this.$store.dispatch("getStudy");
      // this.$store.commit("SET_LOGINSTATUS", localStorage.getItem("user") ? true : false);
      this.getData()
    }
  },
  methods: {
    tojiazhang() {
      console.log(window.location);
      let url = 'https://tuoyu.edu0-6.com/edu/index.html#/jiazhang03/index';
      window.open(url);
    },
    // 去列表
    toList(url, id, index) {
      let myUrl = `/${url}?id=${id}`;
      console.log(url);
      console.log(id);
      if (index) myUrl = myUrl + "&index=" + index;
      this.$until.toPageOpen(myUrl);
    },
    ideaNavChange(index) {
      this.ideaActive = index;
      let numList = [];
      while (numList.length < 3) {
        let num = parseInt(Math.random() * this.ideaAllData.length);
        if (numList.indexOf(num) == -1) {
          numList.push(num);
        }
      }
      (this.ideaData = []),
        (this.ideaData = [
          this.ideaAllData[numList[0]],
          this.ideaAllData[numList[1]],
          this.ideaAllData[numList[2]],
        ]);
    },
    jumpList(name) {
      this.$until.toPageOpen("/" + name);
      // this.$router.push(`./${name}`);
    },
    jumpBannerLesson(item) {
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      this.$until.toPageOpen(`/detail2?id=${item.url}`);
      // this.$router.push(`./detail?id=${item.url}`);
    },
    jumpLesson(item) {
      console.log("detail");
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      this.$until.toPageOpen(`/detail2?id=${item.id}`);
    },
    jumpIdea(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    will(index) {
      console.log(this.plat);
      if (this.plat == "119" || this.plat == "118") {
        this.$toast("敬请期待");
      } else {
        this.$router.push("./lesson?index=" + index);
      }
    },
    async getData() {
      this.pageLoading = true;
      this.$request.tuoyuCategory2({ flat_id: this.plat }).then((res) => {
        this.cateList = res.data;
      });
      let res = await this.$api({
        method: "get",
        url: "/api/platform/university/index",
        data: {
          flat_id: this.plat,
          is_interior: 2,
          terminal: 1, //1 pc 2 小程序
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.banner = res.data.banner || [];

        this.category = res.data.category_list || [];
        if (this.plat == 119) {
          //托育
          //  系统课 163
          this.vipLesson = this.category.find((item) => item.id == 163).list;
          // lessonList
          if(this.category.find((item) => item.id == 207) && (this.plat == 171 || this.plat == 119)){
            this.lessonList = this.category.find((item) => item.id == 207).list;
            console.log(this.lessonList)
          }
          

          // 职业技能认证122
          if(this.category.find((item) => item.id == 122)){
            this.jnLesson = this.category.find((item) => item.id == 122).list;
          }
          
          if(this.category.find((item) => item.id == 226)){
            this.sxList = this.category.find((item) => item.id == 226).list;
            console.log(this.sxList)
          }

          // 国培课
          let gpLesson = this.category.find((item) => item.name == "国培课");
          this.gpLesson = [];
          if (gpLesson) {
            gpLesson.list.forEach((item) => {
              this.gpLesson.push(...item.list[0]);
            });
          }

          // 全国托育服务技能大赛培训班
          let list = this.category.find((item) => item.id == "225");
          console.log(list)
          this.list = [];
          if (list) {
            list.list.forEach((item) => {
              this.list.push(...item.list[0]);
            });
          }
          console.log(this.list)
          let fl =  this.category.find((item) => item.id == 208)
          if(fl){
            this.listFL = fl.list
            // fl.list.forEach((item)=>{
            //   this.listFL.push(...item.list[0]);
            // })
          }
          console.log(fl)
          console.log(this.listFL);
          //  早期能力发展 164
          let zqLesson = this.category.find((item) => item.id == 164);
          this.zqLesson = [];
          if (zqLesson) {
            zqLesson.list.forEach((item) => {
              this.zqLesson.push(...item.list[0]);
            });
          }

          //  托育管理指导 188
          let tyLesson = this.category.find((item) => item.id == 188);
          this.tyLesson = [];
          if (tyLesson) {
            tyLesson.list.forEach((item) => {
              this.tyLesson.push(...item.list[0]);
            });
          }
        } else if (this.plat == 171) {
          //婴幼
          console.log(this.category);
          //  高级托育会员 173
          this.yyList2 = this.category.find((item) => item.id == 173).list;
          this.yyList2Name =  this.category.find((item) => item.id == 173).name;

          // 职业技能认证172
          this.jnLesson = this.category.find((item) => item.id == 172).list[0];

          //  高级幼儿园会员 174
          this.yyList3 = this.category.find((item) => item.id == 174).list;
          this.yyList3Name = this.category.find((item) => item.id == 174).name;

          //  早期能力发展 175
          let zqLesson = this.category.find((item) => item.id == 175);
          this.zqLesson = [];
          if (zqLesson) {
            zqLesson.list.forEach((item) => {
              this.zqLesson.push(...item.list[0]);
            });
          }
          //  托育管理指导 176
          let tyLesson = this.category.find((item) => item.id == 176);
          this.tyLesson = [];
          if (tyLesson) {
            tyLesson.list.forEach((item) => {
              this.tyLesson.push(...item.list[0]);
            });
          }
        }
        if( this.plat == 171 || this.plat == 119){
          this.liveData = res.data.live_stream || [];
        }

        let vipInfo = res.data.plat_vip;
        this.$store.commit("SET_VIP", vipInfo);
      } else {
        this.$toast(res.msg);
      }
    },
    play(item) {
      let { video_id, ccid, name, url, goods_id, id } = item;
      console.log(video_id, ccid);
      let type = "ali";
      if (!video_id && ccid) {
        type = "cc";
        id = ccid;
      } else if (url) {
        type = "url";
      } else {
        this.$toast("没有视频");
        return;
      }
      this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&type=${type}&from=课程&url=${encodeURI(
          url
        )}&goods_id=${goods_id}`
      );
    },
    liveplay(item) {
      if (!this.isLogin) {
        this.$store.commit("changeLoginShow", true);
        return;
      }
      let { name, video_url, id, video_id, goods_id } = item;

      if (video_url || video_id) {
        this.$store.commit("SET_VIDEO", null);
        let url = `/player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(
          video_url
        )}&from=直播&goods_id=${goods_id}&type=ali`;
        this.$until.toPageOpen(url);
      } else {
        this.$toast("没有视频");
      }

      // this.$api({
      //   method: "get",
      //   url: "/api/livestream/click",
      //   data: {
      //     id,
      //   },
      // });
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.$store.commit("SET_PLAT", to.params.plat_type);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #dedee4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  padding-bottom: 90px;
  .index-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    height: 372px;
    // align-items: flex-start;

    .classification {
      flex: 0 1 180px;
      width: 180px;
      height: 100%;
      box-shadow: 0 5px 10px rgb(51 51 51 / 6%);
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      .list {
        padding: 8px 0;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        li {
          flex: 1;
          span {
            font-weight: bold;
          }
        }
        .list-item {
          position: relative;
          width: 100%;
          height: 37px;
          line-height: 30px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 16px;
          color: #333;
          font-size: 16px;
          box-sizing: border-box;

          &:hover {
            background: #edf8ff;
            color: #0080d6;
          }
          i {
            color: #c6c6c6;
          }
        }
      }
    }
    .userInfo {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userBg.jpg");
      display: flex;
      flex-direction: column;
      align-items: center;
      .userLogo {
        padding-top: 20px;
        text-align: center;
        padding-bottom: 10px;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 5px;
        }
        span {
          font-size: 16px;
          color: 393939;
          // font-family: "黑体";
        }
      }
      .studyTime {
        margin-bottom: 80px;
        p {
          font-size: 12px;
          width: 122px;
          display: flex;
          height: 58px;
          align-items: center;
          b {
            flex: 1;
            font-size: 22px;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      .btn {
        cursor: pointer;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
      }
    }
    .noLogin {
      width: 180px;
      box-sizing: border-box;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userNoLogin.png");
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        margin-top: 296px;
        width: 148px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        background: #0080d6;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        span {
          width: 1px;
          height: 15px;
          margin: 0 10px;
          display: block;
          background: #fff;
        }
        p {
          cursor: pointer;
        }
      }
    }
  }

  .banner {
    flex: 0 1 782px;
    margin: 0 29px;
    overflow: hidden;
    height: 100%;
    .banner-item {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .hot {
    width: 100%;
    margin: 16px auto 0;
  }
  .module-title {
    font-size: 36px;
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 41px;
    img {
      margin-right: 10px;
    }
    p {
      flex: 1;
      font-family: "SourceHanSans";
    }
    span {
      color: #006bb3;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .course-card2 {
    // margin-top: 87px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 1200px;
      height: 100%;
      background: #fff;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
    }
    .module-title {
      padding-top: 42px;
      padding-bottom: 11px;
    }
    @keyframes toScale {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes toScaleSmall {
      from {
        transform: scale(1.2);
      }
      to {
        transform: scale(1);
      }
    }
    .course-cover {
      ::v-deep img {
        cursor: pointer;
      }
    }
    .imgBig{
      ::v-deep img {
        animation: toScale 0.3s;
        transform: scale(1.2);
      }
      
    }
    .imgSmall{
      ::v-deep img {
        animation: toScaleSmall 0.3s;
        transform: scale(1);
      }
      
    }
    .course-card2-item {
      padding: 29px 0;
      width: 100%;
      cursor: pointer;
      display: flex;
      &:not(:last-of-type) {
        background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line.jpg")
          bottom no-repeat;
      }
    }
    .course-card-cover {
      width: 560px;
      height: 315px;
      border-radius: 10px;
      overflow: hidden;
    }
    .course-card-detail {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      .course-card-title {
        font-size: 20px;
        font-weight: bold;
      }
      .detail {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #666666;
        line-height: 26px;
      }
      .lessonTime {
        flex: 1;
        font-size: 16px;
        color: #006bb3;
      }
      > div {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        .price {
          color: #ff6c00;
          font-size: 12px;
          b {
            font-size: 24px;
          }
        }
      }
    }
  }
  .live {
    .course-card-title {
      margin-bottom: 20px !important;
      p {
        height: 48px !important;
        -webkit-line-clamp: 2 !important;
        font-size: 16px !important;
      }
    }
  }
  .levelItem {
    .course-card-cover {
      height: auto;
      padding-bottom: 0;
      .course-cover {
        padding-bottom: 0;
        display: block;
      }
    }
    .course-card-detail {
      padding: 16px;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .course-card-title {
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
        overflow: auto;
        height: 80px;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 24px;
          color: #333333;
          font-size: 16px;
        }
        .des {
          font-size: 12px;
          color: #7f7f7f;
        }
        .lessonTm {
          font-size: 12px;
          color: #006bb3;
          font-weight: bold;
        }
      }
    }
  }
  .course-item {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .levelTitle {
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p {
        cursor: pointer;
        font-size: 16px;
        color: #0080d6;
      }
    }
    .course-card {
      margin-right: 20px;
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
    .course-card:nth-of-type(5n) {
      margin: 0;
    }
  }
  .course-list {
    padding-top: 10px;
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      border-radius: 8px;
      margin-right: 20px;
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }

    .levelCate {
      cursor: pointer;
      width: calc((100% - 60px) * 0.25);

      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      b {
        width: 143px;
        height: 35px;
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        text-shadow: 0px 3px 0px #184a8c;
        padding-top: 39px;
        padding-left: 19px;
      }

      p {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color: #dbdbdb;
        padding-left: 22px;
        align-items: center;
      }
    }
    .card-btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 28px;
      border-radius: 6px;
      color: #0080d6;
      background: #f7f7f7;
      padding: 0 20px;
      font-size: 16px;
      flex: 0 0 auto;
      text-align: center;
      margin-top: 4px;

      &:hover {
        color: #fff;
        background: #0080d6;
      }
    }
  }

  .idea,
  .cert {
    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      margin-right: 20px;
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;
      height: 216px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0px 0px 10px #0000000f;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 12px 12px 0 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .tip {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 1;
          img {
            width: 78px;
            height: 35px;
          }
        }
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #0080d6;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #0080d6;
        }
      }
    }
  }

  .top-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 10px;
    .top-card {
      width: calc((100% - 80px) / 5);
      margin-right: 20px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
      cursor: pointer;
      flex: 0 0 calc((100% - 80px) / 5);

      &:hover {
        cursor: pointer;
        transform: translate(0, -6px);
        box-shadow: 0px 14px 36px rgb(0 0 0 / 6%);
        transition: all 0.3s ease;
      }

      .top-card-cover {
        width: 100%;
        height: 224px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        .tip {
          position: absolute;
          top: 0;
          left: 0px;
          z-index: 1;
          img {
            width: 50px;
            // height: 35px;
          }
        }
      }

      .course-card-detail {
        padding: 16px 16px 20px;

        .course-card-title {
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 16px;
        }

        .course-card-subtitle {
          line-height: 18px;
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 12px;
        }

        .course-card-btn-container {
          margin-top: 20px;
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;

          .course-card-learners {
            font-size: 11px;
            line-height: 18px;
          }

          .course-card-btn {
            cursor: pointer;
            border-radius: 6px;
            line-height: 30px;
            font-weight: 500;
            border-radius: 6px;
            color: #a40001;
            background: #f7f7f7;
            padding: 0 20px;
            font-size: 14px;

            // &:hover {
            //   color: #fff;
            //   background: #a40001;
            // }
          }
        }
      }
    }
  }
  .category {
    .tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        margin-right: 10px;
        height: 32px;
        flex: 1;
        text-align: center;
        line-height: 32px;
        height: 32px;
        line-height: 32px;
        margin-right: 20px;
        display: inline-block;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        color: #454545;
        background: #fff;
        &:hover {
          background: #0080d6;
          color: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .active {
        background: #0080d6;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  // .index {
  //   .banner {
  //     width: calc(100% - 60px);
  //     margin: 16px auto 0;
  //   }
  // }
}
</style>

<style lang="scss">
.index {
  .van-skeleton__row {
    height: 26px;
    margin-top: 20px;
  }
}
</style>
<style scoped lang="scss">
.index {
  width: 1200px !important;
  margin: 0 auto;
}
</style>
